import { Injectable } from '@angular/core';

export interface BadgeItem {
  type: string;
  value: string;
}
export interface Saperator {
  name: string;
  type?: string;
}
export interface SubChildren {
  state: string;
  name: string;
  type?: string;
}
export interface ChildrenItems {
  state: string;
  name: string;
  type?: string;
  child?: SubChildren[];
}

export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string;
  permisos?:{};
  badge?: BadgeItem[];
  saperator?: Saperator[];
  children?: ChildrenItems[];
}

const menu2 = {
  compras:0,
  usuarios:0,
  admin:0,
  secciones:0,
  ajustes:0,
  imagenesportadas:0,
  imagenessecundarias:0,
  productos:0
};

console.log(menu2);
/*
  {
                state: 'newsletter',
                name: 'Newsletter',
                type: 'sub',
                icon: 'contact_mail',
                permisos: menu2.newsletter,
                children: [
                  { state: 'boletines', name: 'Boletines', type: 'link' },
                  { state: 'contactos', name: 'Contactos', type: 'link' }
                ]
              },
*/


            const MENUITEMS = [
              {
                state: 'compras',
                name: 'Compras',
                type: 'link',
                icon: 'shopping_cart',
                permisos: menu2.compras
              },
              {
                state: 'productos',
                name: 'Productos',
                type: 'link',
                icon: 'shopping_cart',
                permisos: menu2.productos
              },
              {
                state: 'usuarios',
                name: 'Usuarios',
                type: 'link',
                icon: 'wc',
                permisos: menu2.usuarios
              },
              {
                state: 'admin',
                name: 'Admin',
                type: 'link',
                icon: 'perm_contact_calendar',
                permisos: menu2.admin
              },
              {
                state: 'secciones',
                name: 'Secciones',
                type: 'link',
                icon: 'contact_mail',
                permisos: menu2.secciones
              },
              {
                state: 'imagenessecundarias',
                name: 'Imagenes Secundarias',
                type: 'link',
                icon: 'contact_mail',
                permisos: menu2.imagenessecundarias
              }
            ];


// content_copy

@Injectable()
export class MenuItems {
  menu2 : {};
  getMenuitem(): Menu[] {
    
   

    return MENUITEMS;
  }
}
