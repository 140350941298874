import { Routes } from '@angular/router';

import { FullComponent } from './layouts/full/full.component';
import { AppBlankComponent } from './layouts/blank/blank.component';
import { HomeComponent } from './home/home.component';
import { AjustesComponent } from './ajustes/ajustes.component';


export const AppRoutes: Routes = [
  {
    path: '',
    component: FullComponent,
    children: [
      {
        path: '',
        redirectTo: '/authentication/login',
        pathMatch: 'full'
      },
      {
        path: 'home',
        redirectTo: '/dashboards/dashboard1',
        pathMatch: 'full'
      },
      {
        path: 'usuarios',
        loadChildren:
          './usuarios/usuarios.module#UsuariosComponentsModule'
      },
      {
        path: 'productos',
        loadChildren:
          './productos/productos.module#ProductosComponentsModule'
      },
       {
        path: 'admin',
        loadChildren:
          './admin/admin.module#AdminComponentsModule'
      },
      {
        path: 'ajustes',
        component: AjustesComponent
      },
      {
        path: 'compras',
        loadChildren:
          './compras/compras.module#ComprasComponentsModule'
      },
      {
        path: 'imagenessecundarias',
        loadChildren:
          './imagenessecundarias/imagenessecundarias.module#ImagenesSecundariasComponentsModule'
      },
      {
        path: 'imagenesportadas',
        loadChildren:
          './imagenesportadas/imagenesportadas.module#ImagenesPortadasComponentsModule'
      },
      {
        path: 'dashboards',
        loadChildren: './dashboards/dashboards.module#DashboardsModule'
      },
      {
        path: 'secciones',
        loadChildren:
          './secciones/secciones.module#SeccionesComponentsModule'
      }
    ]
  },
  {
    path: '',
    component: AppBlankComponent,
    children: [
      {
        path: 'authentication',
        loadChildren:
          './authentication/authentication.module#AuthenticationModule'
      }
    ]
  },
  {
    path: '**',
    redirectTo: 'authentication/404'
  }
];
